import React from "react"
import { Link } from "gatsby"

import "./serviceTitle.css";

import Elem1 from '../../images/service/elem_1.png'
import Photo from '../../images/top/photo_service.jpg'

const ServiceTitle  = () => (
  <div className="service-title">
    <div className="title-box service-container">
      <p><img alt=" " src={Elem1} className="scale-to-fit" /></p>
      <h1>SERVICE</h1>
      <p><img alt=" " src={Elem1} className="scale-to-fit" /></p>
    </div>
    <p className="photo service-container"><img alt=" " src={Photo} className="scale-to-fit" /></p>
    <p className="sub-title service-container">
      おなまえメソッド
      <span className="registerd-sign">&#174;</span>
      1DAYトレーニング、2種類の講師、認定講師と自分自身を深く知って自分に惚れてしまうトレーニングから起業家として独立を目指す全てのお客様にサービスを提供しています！
    </p>
    <div className="btn-holder gr">
      <Link to='/service'>サービス詳細はこちら</Link>
    </div>
  </div>
)

export default ServiceTitle
