import React, { useState, useEffect } from "react"
import axios from "axios"

import "./instagram.css";

import Instagram_logo from '../../images/common/Instagram_logo.svg';
import Mate_logo_left from '../../images/common/mate_logo_left.png';
import Mate_logo_right from '../../images/common/mate_logo_right.png';

const Instagram = () => {

  const [ dataArray, setDataArray ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    fetchDataArray()
  }, []);

  const fetchDataArray = () => {
    axios.get('https://ik-backend.herokuapp.com/onamae/getIinstagramPhotos')
      .then(response => {
        if (!response.data.error) {
          setLoading(false);
          setDataArray(response.data.response);
        } else {
          console.log('error', response.data.error);
        }
      })
      .catch(error => {
        console.log('error', error);
      })
  }

  const renderList = () => dataArray.slice(0, 6).map(data => <li key={data.id}><a href={data.permalink} target="_blank" rel="noreferrer"><img alt=" " src={data.media_url} className="scale-to-fit" /></a></li>);

  return (
    <section className="instagram">
      <div className="logo">
        <p className="left">
          <img alt="left" src={Mate_logo_left} className="scale-to-fit" />
        </p>
        <p className="center">
          <img alt="Instagram" src={Instagram_logo} className="scale-to-fit" />
        </p>
        <p className="right">
          <img alt="right" src={Mate_logo_right} className="scale-to-fit" />
        </p>
      </div>
      <h2 className="sub">最新のお知らせも発信中！</h2>
      { loading ? 
        <div className="loader">Loading...</div>
        :
        <ul>
          { renderList() }
        </ul>
      }
    </section>
  )
}

export default Instagram
