import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.css";
import ExplanatoryTextGift from "../components/common/explanatoryTextGift" 
import Works from "../components/common/works" 
import Categories from "../components/common/categories" 
import ServiceTitle from "../components/top/serviceTitle" 
import DiagnosisArea from "../components/top/diagnosisArea" 
import Instagram from "../components/top/instagram" 

import Bk_top from '../images/top/bk_top.jpg';
import Signature from '../images/top/signature.png';
import Banner_1 from '../images/top/banner_1.jpg';
import Banner_3 from '../images/top/banner_3.jpg';
import Banner_4 from '../images/top/banner_4.jpg';
import Profile_mark from '../images/top/profile_mark.png';
import Profile_photo from '../images/top/profile_photo.jpg';
import Profile_text from '../images/top/profile_text.png';

const IndexPage = () => (
  <Layout>
    <SEO title="TOP" />
    <div className="top">
      <section className="gallery">
        <div className="image">
          <img alt=" " src={Bk_top} className="scale-to-fit" />
        </div>
        <div className="signature">
          <img alt=" " src={Signature} className="scale-to-fit" />
        </div>
        <p className="copy ff-min">自分を磨く、未来を磨く</p>
      </section>
      <section className="read">
        <ul className="ff-min">
          <li>この世に1人しかいない、あなたに付けられた</li>
          <li>
            <span>たった1つの名前は</span>まるで写し鏡のように、
          </li>
          <li>これまでの自分も、これからの未来も教えてくれる</li>
        </ul>
      </section>
      <section className="banner">
        <ul>
          <li>
            <Link to='/about'><img alt="About" src={Banner_1} className="scale-to-fit hover-img" /></Link>
          </li>
          <li>
            <Link to='/service'><img alt="Service" src={Banner_4} className="scale-to-fit hover-img" /></Link>
          </li>
          <li>
            <Link to='/diagnocy'><img alt="Diagnocy" src={Banner_3} className="scale-to-fit hover-img" /></Link>
          </li>
        </ul>
      </section>
      <ExplanatoryTextGift />
      <Works />
      <Categories />
      <ServiceTitle />
      <DiagnosisArea />
      <section className="profile">
        <div className="main">
          <p className="mark">
            <img alt=" " src={Profile_mark} className="scale-to-fit" />
          </p>
          <p className="name">MILLY HOSHINA</p>
          <p className="photo">
            <img alt=" " src={Profile_photo} className="scale-to-fit" />
          </p>
          <div className="info">
            <p className="en">ONAMAE METHOD&reg;</p>
            <p className="ja">私分析学おなまえメソッド&reg;</p>
            <p className="name"><span>代表</span> 星名 美利</p>
          </div>
          <p className="text">
            <img alt="星名美利" src={Profile_text} className="scale-to-fit" />
          </p>
        </div>
        <div className="side-text">
          <p>|おなまえメソッド&reg;&nbsp;ONAMAE METHOD&reg;|</p>
        </div>
        <div className="btn-holder">
          <Link to='/about'>SEEMORE</Link>
        </div>
      </section>
      <Instagram /> 
    </div>
  </Layout>
)

export default IndexPage
