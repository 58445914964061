import React from "react"
import { Link } from "gatsby"

import "./diagnosisArea.css";

import Diagnocy_banner from '../../images/top/diagnocy_banner.jpg';
import Papers from '../../images/top/papers.png';

const DiagnosisArea = () => (
  <section className="diagnosis-area">
    <div className="circle">
      名前をローマ字で<br />
      入力するだけで<br />
      今日のハッピーワードが！
    </div>
    <ul>
      <li className="banner">
        <p>
          <img alt="NAME DIAGNOSIS" src={Diagnocy_banner} className="scale-to-fit" />
        </p>
      </li>
      <li className="entry">
        <div className="text">
          <p>Secret NAME Cord</p>
          <p>今日のハッピーを手に入れよう</p>
          <p className="papers">
            <img alt="papers" src={Papers} className="scale-to-fit" />
          </p>
        </div>
      </li>
    </ul>
    <div className="btn-holder">
      <Link to='/diagnocy'>診断はこちら</Link>
    </div>
  </section>
)

export default DiagnosisArea
